/*
 * Use this CSS file ONLY FOR EDGE CASES -> refer to GITLAB ISSUES TO EXPLAIN WHY YOU ARE DOING WHAT YOU ARE DOING
 */

html,
body {
  min-height: 580px;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans', Arial, sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
}

html {
  overflow-y: hidden;
  display: none;
}

@media print {
  body, html {
    overflow: visible !important;
  }
}

* {
  box-sizing: border-box;
}

.grecaptcha-badge { visibility: hidden; }

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 1px solid #e30613;
  color: #e30613;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
  color: #e30613;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
  color: #e30613;
}

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  background-color: transparent;
  border: 2px solid #e30613;
  border-radius: .25em;
  color: #141414;
  display: flex;
  font-weight: 600;
  justify-content: center;
  padding: 1em;
  user-select: none;
  width: 100%;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: #e30613;
  border-color: #e30613;
  color: #ffffff;
}

#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
  background-color: #e30613;
  border-color: #e30613;
  color: #FFFFFF;
}

#CookiebotWidget.CookiebotWidget-open {
  margin-right: 20px !important;
  max-height: 80% !important;
  overflow: hidden auto !important; 
  z-index: 2147483631 !important;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
  fill: #e30613;
  width: 18px;
}

#CookiebotWidget .CookiebotWidget-consent-details button {
  display: flex;
  align-items: center;
  color: #e30613;
  font-weight: 600;
  margin-bottom: 12px;
  cursor: pointer;
}

#CookiebotWidget {
  bottom: 20px !important;
  left: 20px !important;
  right: auto !important;
  z-index: 2 !important;
}

/* Branding on the banner */
.CookieDeclaration,
#CybotCookiebotDialogHeader,
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none !important;
}

#showCookieDeclaration .CookieDeclaration
{
  display: block !important;
}

/* Branding on the widget */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
    display: none;
}

/* HARDCODED */
@media only screen and (max-width: 1200px) {
  #CookiebotWidget {
    bottom: 80px !important;
    left: 20px !important;
    right: auto !important;
  }
}

::marker {
  display: none;
  padding: 0;
  width: 0;
  font-size: 0;
}